// eslint-disable-next-line max-len
/* eslint-disable no-unused-vars,react/no-direct-mutation-state,react/state-in-constructor,react/prop-types,react/destructuring-assignment,react/jsx-filename-extension */
import React, { Component } from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarContent from '@mui/material/SnackbarContent';

class Alerts extends Component {
    state = {
      isActive: false,
      message: null,
      cssClass: null,
    };

    render() {
      const { alertState, handleRequestClose, message } = this.props;
      switch (alertState) {
        case 'success':
          this.state.isActive = true;
          this.state.message = message;
          this.state.cssClass = 'mb-3 bg-primary alertSpacing';
          break;
        case 'failure':
          this.state.isActive = true;
          this.state.message = message;
          this.state.cssClass = 'mb-3 alertFailure alertSpacing';
          break;
        case 'warning':
          this.state.isActive = true;
          this.state.message = message;
          this.state.cssClass = 'mb-3 alertWarning alertSpacing';
          break;
        case 'information':
          this.state.isActive = true;
          this.state.message = message;
          this.state.cssClass = 'mb-3 alertInformation alertSpacing';
          break;
        case 'close':
          this.state.isActive = false;
          break;
        default:
          this.state.isActive = false;
      }
      return (
        <div>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.isActive}
            autoHideDuration={6e3}
            onClose={handleRequestClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
          >
            <SnackbarContent
              className={this.state.cssClass}
              message={message}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={handleRequestClose}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />

          </Snackbar>

        </div>
      );
    }
}

export default Alerts;
